import React, { useEffect, useState } from "react";
import './tailwind.css';

function App() {
  const [plugins, setPlugins] = useState([]);
  const [count, setCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage] = useState(30);

  useEffect(() => {
    // 从 JSON 文件加载数据
    fetch("https://raw.githubusercontent.com/huangyafei/chatgpt-plugin-list/master/chatgpt-plugin-list.json")
      .then((res) => res.json())
      .then((data) => {
        setPlugins(data.items);
        setCount(data.count);
      })
      .catch((err) => console.error(err));
  }, []);

  // 分页逻辑
  const indexOfLastPlugin = currentPage * perPage;
  const indexOfFirstPlugin = indexOfLastPlugin - perPage;
  const currentPlugins = plugins.slice(indexOfFirstPlugin, indexOfLastPlugin);

  return (
    <div className="App p-4">
      <h1 className="text-2xl mb-4">ChatGPT Plugin List</h1>
      
      {currentPlugins.map((plugin, index) => (
        <div key={index} className="bg-white shadow p-4 mb-4">
          <div className="mb-2">
            <h2 className="text-xl text-blue-500">{plugin.manifest.name_for_human}</h2>
            <p className="text-gray-600">{plugin.manifest.description_for_human}</p>
          </div>
          <div className="mb-2">
            <img className="w-12 h-12" src={plugin.manifest.logo_url} alt={plugin.manifest.name_for_human} />
          </div>
        </div>
      ))}
      
      {/* 分页按钮 */}
      <div className="flex justify-center mt-4 overflow-x-auto">
        {Array.from({ length: Math.ceil(plugins.length / perPage)}, (_, i) =>
          <button key={i} onClick={() => setCurrentPage(i + 1)} 
            className={`px-3 py-2 mx-2 rounded-md ${currentPage === i + 1 ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
          >
            {i + 1}
          </button>
        )}
      </div>
      <div className="mt-4">Total Plugins: {count}</div>
    </div>
  );
}

export default App;